import {
    EweLinkAuthUrlResponse,
    EweLinkDeviceListResponse, EweLinkDeviceOperationResponse,
    EweLinkGrantResponse
} from "../../../common/types/ResponseType";
import {CommonApiService} from "@/service/CommonApiService";


export default class EweLinkApiService {

    public async fetchAuthUrl():Promise<EweLinkAuthUrlResponse>{
        const response = await fetch('/api/ewelink/auth-url', {
           method:'POST'
        });
        return await response.json() as EweLinkAuthUrlResponse;
    }

    public async getGrantResult(code: string):Promise<EweLinkGrantResponse>{
        const urlParams = new URLSearchParams();
        urlParams.append('code', code);
        const response = await fetch('/api/ewelink/auth-grant',{
            method:'POST',
            body: urlParams
        });
        return await  response.json() as EweLinkGrantResponse;
    }

    public async getDeviceList(): Promise<EweLinkDeviceListResponse>{
        const response = await fetch('/api/ewelink/device-list');
        return await response.json() as EweLinkDeviceListResponse;
    }

    public async changeSwitchStatus(deviceId: string, targetStatus: boolean): Promise<EweLinkDeviceOperationResponse>{
        return await CommonApiService.postJsonData('/api/ewelink/switch-status', {
            deviceId: deviceId,
            status: targetStatus
        }) as EweLinkDeviceOperationResponse;
    }


}