



































import Vue from "vue";
import EweLinkApiService from "@/service/EweLinkApiService";
import {EweLinkDeviceItem} from "../../../../common/types/ResponseType";

const eweLinkApiService = new EweLinkApiService();

export default Vue.extend({
  name: "Info.vue",
  data:()=>({
    authStatus: false,
    devices: [] as EweLinkDeviceItem[],
    pageMessage: "Loading Devices",
  }),
  created() {
    this.loadSwitchStatus();
  },
  methods:{
    async loadSwitchStatus(){
      const deviceList = await eweLinkApiService.getDeviceList();
      if(deviceList.code !== 0 || !deviceList.data){
        this.pageMessage = !deviceList.message?"unknown error" : deviceList.message;
        return;
      }
      this.pageMessage = '';
      this.authStatus = deviceList.data.authStatus;
      this.devices = deviceList.data.devices;
    },
    async toggleSwitchStatus(deviceId:string, targetStatus: boolean){
      console.log("toggle switch status", deviceId, targetStatus);
      this.pageMessage = 'Changing switch status';
      const response = await eweLinkApiService.changeSwitchStatus(deviceId, targetStatus);
      if(response.code !== 0){
        console.log("change switch status failed:", deviceId, targetStatus, response);
        this.pageMessage = !response.message?'unknown error': response.message ;
        return;
      }
      this.pageMessage = '';
    }
  }
});
