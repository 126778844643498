















import Vue from "vue";
import EweLinkApiService from "@/service/EweLinkApiService";


export default Vue.extend({
  name: "OauthCallback",
  props:{
    code: String
  },
  data:()=>({
    grantStatus: "Loading...",
    hasError: false
  }),
  created() {
    console.log("auth code", this.code);
    this.getGrantResult();
  },
  methods:{
    async getGrantResult(){
      const ewelinkApiService = new EweLinkApiService();
      const result = await ewelinkApiService.getGrantResult(this.code);
      if(result.code === 0){
        this.grantStatus = "Granted";
      }else{
        this.grantStatus = result.message ? result.message : "Unknown Error";
        this.hasError = true;
      }

    }
  }
});
