










import Vue from "vue";
import EweLinkApiService from "@/service/EweLinkApiService";


export default Vue.extend({
  name: "Auth",
  data:()=>(
      {
        showLoading: true,
        tip: "Fetching Auth url"
      }
  ),
  created() {
    this.fetchAuthUrl();
  },
  methods:{
    fetchAuthUrl(){
      const ewelinkService = new EweLinkApiService();
      ewelinkService.fetchAuthUrl().then(resp=>{
        this.showLoading = false;
        if(resp.code !== 0 || !resp.data){
          console.log("failed to fetch url", resp.message);
          this.tip = resp.message == undefined ? "Unknown error" : resp.message;
          return;
        }
        console.log("auth url:", resp.data.link);
        this.tip = resp.data.link;
        location.replace(resp.data.link);
      });
    }
  }
});
